import React, { useEffect, useState, useRef } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFocusOnMount, ListNavigation, withVisibilityLogic } from 'mangools-react-components/src';
import ReactList from 'react-list';

import shortcuts, { ARROW_DOWN, ARROW_UP, ENTER } from 'constants/Shortcuts';

import HistoryPanelItem from 'components/panels/historyPanel/HistoryPanelItem';
import PanelHistoryItemPreloader from 'components/panels/PanelHistoryItemPreloader';

import HistoryType from 'types/HistoryType';

import emptyImg from 'images/sad.svg';

function HistoryPanel(props) {
    const closeRef = useFocusOnMount();
    const listRef = useRef(null);
    const [preselectedItemId, setPreselectedItemId] = useState(null);

    useEffect(() => {
        props.onOpen();
    }, []);

    useEffect(() => {
        if (props.data.length > 0) {
            setPreselectedItemId(props.data[0].key);
        }
    }, [props.data]);

    const handleArrowUp = ([historyItem, newIndex]) => {
        setPreselectedItemId(historyItem.key);
        listRef.current.scrollAround(newIndex - 3);
    };

    const handleArrowDown = ([historyItem, newIndex]) => {
        setPreselectedItemId(historyItem.key);
        listRef.current.scrollAround(newIndex + 3);
    };

    const handleEnter = () => {
        const item = props.data.find(entry => entry.key === preselectedItemId);

        setPreselectedItemId(null);
        props.onClick({
            linksPerDomain: item.linksPerDomain,
            source: item.source,
            url: item.url,
        });
    };

    const renderEmptyMessage = () => (
        <div className="uk-flex uk-flex-middle uk-flex-center uk-text-center uk-height-1-1">
            <div>
                <div className="mg-margin-b-15">
                    <img src={emptyImg} alt="Oh no" width="120" />
                </div>
                <h3 className="font-24 uk-text-bold">Your history is empty</h3>
                <p className="mg-margin-b-10">
                    Start your backlinks research now, your recent searches will be listed here.
                </p>
                <button className="mg-btn is-link is-small" onClick={props.onClose} type="button" ref={closeRef}>
                    Close this panel
                </button>
            </div>
        </div>
    );

    const renderItems = (items, ref) => <div ref={ref}>{items}</div>;

    const renderItem = index => {
        const item = props.data[index];

        return (
            <HistoryPanelItem
                key={`${item.id}-${index}`}
                onClick={props.onClick}
                source={item.source}
                linksPerDomain={item.linksPerDomain}
                url={item.url}
                isPreselected={item.key === preselectedItemId}
            />
        );
    };

    /* eslint-disable react/no-array-index-key */
    // Because of duplicates which we want to keep in list
    const renderHistoryItems = () => {
        return (
            <ListNavigation
                data={props.data}
                selectedItemId={preselectedItemId}
                itemId="key"
                onArrowDownShortcut={handleArrowDown}
                onArrowUpShortcut={handleArrowUp}
                onEnterShortcut={handleEnter}
                shortcutKeys={[ARROW_DOWN, ARROW_UP, ENTER]}
                shortcuts={shortcuts}
            >
                <ReactList
                    itemRenderer={renderItem}
                    itemsRenderer={renderItems}
                    length={props.data.length}
                    type="uniform"
                    updateWhenDataChange={props.data}
                    ref={listRef}
                />
            </ListNavigation>
        );
    };
    /* eslint-enable react/no-array-index-key */

    const renderBody = () => {
        if (props.fetching) {
            return (
                <div style={{ cursor: 'progress' }}>
                    <PanelHistoryItemPreloader />
                    <PanelHistoryItemPreloader />
                    <PanelHistoryItemPreloader />
                    <PanelHistoryItemPreloader />
                    <PanelHistoryItemPreloader />
                </div>
            );
        } else if (props.data.length > 0) {
            return renderHistoryItems();
        } else {
            return renderEmptyMessage();
        }
    };

    return (
        <div className="mg-panel mg-panel--history-box">
            <div className="mg-panel-title mg-padding-30 mg-border-b">
                <div className="uk-flex uk-flex-space-between uk-flex-middle">
                    <h1 className="font-24 uk-margin-remove">
                        <FontAwesomeIcon icon="history" aria-hidden="true" className="mg-margin-r-10" />
                        <strong>Search history</strong>
                    </h1>
                    <button
                        className="mg-btn is-xsmall is-white-red mg-margin-r-30 uk-hidden-small"
                        onClick={props.onDeleteHistory}
                        disabled={props.fetching || props.data.length === 0}
                        type="button"
                    >
                        <FontAwesomeIcon icon={['far', 'trash-alt']} className="mg-margin-r-5" />
                        Clear history
                    </button>
                    <button className="mg-close" onClick={props.onClose} type="button" ref={closeRef}>
                        <FontAwesomeIcon icon="times" />
                    </button>
                </div>
            </div>
            <div className="mg-panel-content">{renderBody()}</div>
        </div>
    );
}

HistoryPanel.propTypes = {
    data: arrayOf(HistoryType).isRequired,
    fetching: bool.isRequired,
    onClick: func.isRequired,
    onClose: func.isRequired,
    onOpen: func.isRequired,
    onDeleteHistory: func.isRequired,
};

export default withVisibilityLogic(React.memo(HistoryPanel));
