import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import { func, number, string, bool } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import isNil from 'ramda/src/isNil';
import { Favicon } from 'mangools-react-components/src';
import Defaults from 'mangools-commons/lib/constants/Defaults';

const MAX_BACKLINK_LIMIT = 1000;

class FavoriteItem extends PureComponent {
    constructor(props) {
        super(props);

        this.deleteList = this.deleteList.bind(this);
        this.formatCreatedAt = this.formatCreatedAt.bind(this);
        this.renderLink = this.renderLink.bind(this);
    }

    deleteList() {
        this.props.onDeleteFavorite({ listId: this.props.id, domain: this.props.domain });
    }

    formatCreatedAt() {
        if (!isNil(this.props.createdAt)) {
            const date = new Date(this.props.createdAt);
            const dayString = date.getDate().toString();
            const monthString = (date.getMonth() + 1).toString();

            const day = dayString.length === 1 ? `0${dayString}` : dayString;
            const month = monthString.length === 1 ? `0${monthString}` : monthString;

            return `${day}/${month}/${date.getFullYear()}`;
        } else {
            return Defaults.NOT_AVAILABLE;
        }
    }

    renderLinkBody() {
        const badgeClasses = classnames({
            'color-red': this.props.backlinkCount >= MAX_BACKLINK_LIMIT,
            'color-black': this.props.backlinkCount < MAX_BACKLINK_LIMIT,
        });

        return (
            <>
                <div className="uk-flex-inline uk-flex-middle uk-width-1-1">
                    <Favicon className="mg-margin-r-5" domain={this.props.domain} size="16" />
                    <strong className="font-18 mg-truncate ln-11 color-blue mg-truncate uk-display-inline-block">
                        {this.props.domain}
                    </strong>
                </div>
                <div className="uk-flex font-12 color-grey mg-margin-t-5">
                    <div className="uk-width-1-2">
                        <span title="Number of backlinks in list">
                            <strong className={badgeClasses}>{this.props.backlinkCount}</strong>
                            &nbsp;/ {MAX_BACKLINK_LIMIT}
                        </span>
                    </div>
                    <div className="uk-width-1-2">{this.formatCreatedAt()}</div>
                </div>
            </>
        );
    }

    renderLink() {
        const wrapperClasses = classnames(
            'mg-card',
            'is-hover',
            'mg-padding-30',
            'mg-no-underline',
            'uk-display-block',
            {
                'is-focus': this.props.isPreselected,
            },
        );

        if (this.props.backlinkCount > 0) {
            return (
                <Link onClick={this.props.onClose} to={this.props.openLink} className={wrapperClasses}>
                    {this.renderLinkBody()}
                </Link>
            );
        } else {
            return (
                <div className="mg-card mg-padding-30 uk-display-block mg-no-interactive">{this.renderLinkBody()}</div>
            );
        }
    }

    render() {
        return (
            <div
                className="mg-margin-b-15 uk-position-relative ln-11"
                style={{ opacity: this.props.backlinkCount > 0 ? 1 : 0.5 }}
            >
                {this.renderLink()}
                {/* eslint-disable-next-line max-len */}
                <div className="uk-position-top-right mg-padding-30 uk-position-z-index uk-height-1-1 uk-flex uk-flex-middle">
                    <button
                        aria-label="Delete"
                        className="mg-icon-btn"
                        onClick={this.deleteList}
                        tabIndex="-1"
                        title="Delete list"
                        type="button"
                    >
                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                    </button>
                </div>
            </div>
        );
    }
}

FavoriteItem.propTypes = {
    backlinkCount: number.isRequired,
    createdAt: number.isRequired,
    domain: string.isRequired,
    id: string.isRequired,
    onClose: func.isRequired,
    onDeleteFavorite: func.isRequired,
    openLink: string.isRequired,
    isPreselected: bool.isRequired,
};

export default FavoriteItem;
