import { EVENTS, UnleashClient } from 'unleash-proxy-client';
import Rollbar from './rollbar';

const unleashClient = new UnleashClient({
    url: process.env.UNLEASH_PROXY_URL || '',
    clientKey: process.env.UNLEASH_CLIENT_KEY || '',
    appName: process.env.UNLEASH_APP_NAME || '',
    environment: process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
    refreshInterval: 180,
    metricsInterval: 180,
});

unleashClient.on(EVENTS.ERROR, (error) => {
    Rollbar.warning(error);
    console.error('Unleash Error:', error);
});

export { unleashClient };