import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import not from 'ramda/src/not';
import isNil from 'ramda/src/isNil';
import hasPath from 'ramda/src/hasPath';

import ActionTypes from 'constants/ActionTypes';

const initialState = {
    accessToken: null,
    createdAt: null,
    email: null,
    error: {
        status: null,
        text: null,
    },
    unleashSession: null,
    fetched: false,
    fetching: false,
    id: null,
    limits: {
        fetched: false,
        fetching: false,
        relatedRowsLimit: 0,
        trackedKeywordLimit: 0,
        backlinkRequestRemaining: 0,
        upgradedAccount: false,
        limitOptions: [],
        error: {
            status: null,
            text: null,
        },
    },
    loggedIn: false,
    plan: null,
    ssoTicket: null,
    loginToken: null,
    isSubUser: false,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_USER_DATA_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_USER_DATA_RECEIVED: {
            const hasAccessToken = not(isNil(action.payload.accessToken));

            return update(state, {
                accessToken: { $set: action.payload.accessToken },
                createdAt: { $set: action.payload.createdAt },
                email: { $set: action.payload.email },
                isSubUser: { $set: action.payload.isSubUser },
                fetched: { $set: true },
                fetching: { $set: false },
                id: { $set: action.payload.id },
                loggedIn: { $set: hasAccessToken },
                plan: { $set: action.payload.plan },
                ssoTicket: { $set: !hasAccessToken ? null : state.ssoTicket },
                loginToken: { $set: !hasAccessToken ? null : state.loginToken },
            });
        }
        case ActionTypes.DATA_USER_DATA_ERROR: {
            return update(state, {
                fetched: { $set: false },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_USER_CHECK_STATUS_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_USER_DATA_SKIPPED: {
            return update(state, {
                fetched: { $set: true },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_FETCHING: {
            return update(state, {
                limits: {
                    fetching: { $set: true },
                },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_RECEIVED: {
            return update(state, {
                limits: {
                    fetched: { $set: true },
                    fetching: { $set: false },
                    relatedRowsLimit: { $set: action.payload.relatedRowsLimit },
                    trackedKeywordLimit: { $set: action.payload.trackedKeywordLimit },
                    upgradedAccount: { $set: action.payload.upgradedAccount },
                    limitOptions: { $set: action.payload.limitOptions },
                    backlinkRequestRemaining: { $set: action.payload.backlinkRemaining },
                },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_ERROR: {
            return update(state, {
                limits: {
                    fetched: { $set: true },
                    fetching: { $set: false },
                    error: {
                        status: { $set: action.payload.status },
                        text: { $set: action.payload.text },
                    },
                },
            });
        }
        case ActionTypes.DATA_USER_LIMIT_DATA_SKIPPED: {
            return update(state, {
                limits: {
                    fetched: { $set: true },
                    fetching: { $set: false },
                },
            });
        }
        case ActionTypes.DATA_USER_SSO_TICKET_RECEIVED: {
            return update(state, {
                ssoTicket: { $set: action.payload },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_RECEIVED: {
            return update(initialState, {
                fetched: { $set: true },
                limits: {
                    fetched: { $set: true },
                },
            });
        }
        case ActionTypes.DATA_USER_LOGOUT_ERROR: {
            return update(state, {
                error: {
                    status: { $set: action.payload.status },
                    text: { $set: action.payload.text },
                },
            });
        }
        case ActionTypes.DATA_USER_AUTH_TOKENS_RECEIVED: {
            const { ssoTicket, loginToken } = action.payload;

            return update(state, {
                ssoTicket: { $set: ssoTicket },
                loginToken: { $set: loginToken },
            });
        }
        case ActionTypes.DATA_USER_SET_UNLEASH_SESSION: {
            return update(state, {
                unleashSession: { $set: action.payload.unleashSession },
            });
        }
        case REHYDRATE: {
            return update(state, {
                ssoTicket: {
                    $set: hasPath(['user', 'ssoTicket'], action.payload) ? action.payload.user.ssoTicket : null,
                },
                loginToken: {
                    $set: hasPath(['user', 'loginToken'], action.payload) ? action.payload.user.loginToken : null,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default userReducer;
